<script>
export default {
  data: function () {
    return {
      item: null,
      storageUrl: null,
      options: null,
      value: null
    }
  },
  created () {
    this.storageUrl = process.env.VUE_APP_STORAGE_URL
    this.item = this.params.data
    this.options = this.params.image
    this.value = this.params.value
    this.imgPath = `${this.storageUrl}/${this.options.path}/${this.value}`
  },
  methods: {
    refresh (params) {
      this.params = params
    }
  }
}
</script>

<template>
  <div class="text-center">
    <a
      v-if="item[options.filename] || value != null"
      :href="imgPath"
      target="_blank"
    >
      <img
        class="h-6 w-6 m-auto"
        :class="options.classes"
        :src="imgPath"
      />
    </a>
    <i v-else class="fas fa-user" />
  </div>
</template>
