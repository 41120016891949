<script>
export default {
  data: function () {
    return {
      files: null
    }
  },
  created () {
    this.files = this.params.value || []
  },
  filters: {
    fileSize: function (num) {
      var base = 1024
      var prefixes = ['k', 'M', 'G', 'T']
      var exp = Math.log(num) / Math.log(base) | 0
      return (num / Math.pow(base, exp)).toFixed(1) + ' ' + ((exp > 0) ? prefixes[ exp - 1 ] + 'o' : 'Bytes')
    }
  }
}
</script>

<template>
  <div>
    <span v-if="files.length > 0">{{ files.length }} fichiers ({{ files.reduce((acc, event) => acc += event.size, 0) | fileSize }})</span>
    <span v-else>Pas de pièce jointe</span>
  </div>
</template>
