<script>
export default {
  data: function () {
    return {
      rating: null
    }
  },
  created (params) {
    this.rating = this.params.data.rating
  },
  methods: {
    refresh (params) {
      this.params = params
    },
    getRatingBarColor (number) {
      if (number > 66) {
        return 'bg-green-200'
      } else if (number > 33) {
        return 'bg-yellow-200'
      } else {
        return 'bg-red-200'
      }
    }
  }
}
</script>

<template>
  <div class="h-full p-1">
    <div class="h-full" v-if="rating.rates">
      <div class="w-full bg-gray-200 rounded-full h-full mb-4 dark:bg-gray-700 relative">
        <div
          class="h-full rounded-full"
          :class="getRatingBarColor(rating.percent)"
          :style="`width: ${rating.percent}%`"
        >

        </div>
        <div class="flex items-center justify-center absolute top-0 left-0 right-0 bottom-0 text-xxs leading-none">
          {{ rating.rating }}/5 - {{ rating.rates }} avis
        </div>
      </div>
    </div>
    <div v-else>
      -
    </div>
  </div>
</template>
