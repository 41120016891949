<script>

export default {
  data () {
    return {
      date: null,
      formattedDate: ''
    }
  },
  created () {
    this.date = new Date(this.params.value)
    this.formattedDate = this.formatDate(this.date)
  },
  methods: {
    formatDate (date) {
      if (!date) return ''
      return new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    refresh (params) {
      this.params = params
      this.date = new Date(this.params.value)
      this.formattedDate = this.formatDate(this.date)
    }
  }
}
</script>

<template>
  <div>
    <span>{{ formattedDate }}</span>
  </div>
</template>
