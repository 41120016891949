<script>
export default {
  data () {
    return {
      value: null
    }
  },
  created (params) {
    this.value = this.params.value
      .map((item) => item.label)
      .join(', ')
  }
}
</script>

<template>
    <span v-if="value">{{ value }}</span>
    <span v-else>-</span>
</template>
