<script>
export default {
  data: function () {
    return {
      storageUrl: null,
      user: null
    }
  },
  created (params) {
    this.storageUrl = process.env.VUE_APP_STORAGE_URL
    this.user = this.params.data
  },
  methods: {
    refresh (params) {
      this.params = params
    }
  }
}
</script>

<template>
  <div class="text-center">
    <img
      v-if="user.avatar"
      class="h-6 w-6 m-auto"
      :src="`${storageUrl}/avatars/${user.avatar}.png`"
    />
    <i v-else class="fas fa-user" />
  </div>
</template>
