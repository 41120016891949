<script>
export default {
  data () {
    return {
      value: null
    }
  },
  created () {
    this.value = this.params.value || this.params.value === 'true'
  }
}
</script>

<template>
  <div>
    <div v-if="params.value === 'Tout sélectionner'">
      Tout sélectionner
    </div>
    <div v-else
      :class="{
        'text-green-700': this.value,
        'text-yellow-700': !this.value
      }"
    >
      {{ params.tag.labels[this.value ? 'true' : 'false'] }}
    </div>
  </div>
</template>
