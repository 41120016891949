<template>
  <div class="ag-status-name-value">
      <div class="component">
        Lignes: <span class="text-black">{{ totalRowCount }}</span>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      totalRowCount: 0
    }
  },
  mounted () {
    this.updateRowCount()
    this.params.api.addEventListener('totalRowCountUpdated', this.updateRowCount)
  },
  beforeDestroy () {
    this.params.api.removeEventListener('totalRowCountUpdated', this.updateRowCount)
  },
  methods: {
    init (params) {
      this.params = params
    },
    updateRowCount () {
      if (this.params && this.params.context && this.params.context.componentParent) {
        this.totalRowCount = this.params.context.componentParent.totalRowCount
      }
    }
  }
}
</script>
