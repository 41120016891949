<script>
export default {
  data () {
    return {
      value: null
    }
  },
  created () {
    this.value = this.params.value || this.params.value === 'true'
  }
}
</script>

<template>
  <div
    class="inline-flex justify-center items-center px-3 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800 w-full"
    :class="{
      'bg-green-400': this.value,
      'bg-yellow-400': !this.value
    }"
  >
    <span> {{ params.tag.labels[this.value ? 'true' : 'false'] }} </span>
  </div>
</template>
