<script>
export default {
  methods: {
    handleClick () {
      const rowData = this.params.data
      this.params.onClick(rowData)
    }
  }
}
</script>

<template>
  <div
    class="text-center cursor-pointer"
    @click="handleClick"
    :class="params.cellClass"
  >
    <i :class="params.iconClass"></i>
  </div>
</template>
