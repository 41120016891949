<script>
export default {
  props: ['onClick'],
  data: function () {
    return {
      valueCleaned: null
    }
  },
  created (params) {
    this.valueCleaned = this.booleanCleaner(this.params.value)
  },
  methods: {
    refresh (params) {
      this.params = params
      this.valueCleaned = this.booleanCleaner(this.params.value)
    },
    booleanCleaner (value) {
      if (value === 'true' || value === true || value === 1) {
        return true
      } else if (value === 'false' || value === false || value === 0) {
        return false
      } else if (typeof value === 'object' && value !== null && Object.keys(value).length) {
        return true
      } else if (value !== null) {
        return true
      }
      return null
    },
    handleClick () {
      const rowData = this.params.data
      this.params.onClick(rowData)
    }
  }
}
</script>

<template>
  <span @click="handleClick" :class="{ 'cursor-pointer' : onClick != null }">
    <i v-if="valueCleaned === true" class="fas fa-check text-green-600"></i>
    <i v-else-if="valueCleaned === false" class="fas fa-times text-red-600"></i>
    <i v-else>{{ valueCleaned }}</i>
  </span>
</template>
